/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://bw3snxa3cndjvcwkdqnh2sfn2u.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gagaf3dcw5gazmwf34vnbstwke",
    "aws_cognito_identity_pool_id": "us-east-2:0826b893-18fb-4f59-b3d5-f72c6e4f1359",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_peP5DfNo7",
    "aws_user_pools_web_client_id": "73l8hbq9isa6g5t3qfvk4t5n6r",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
